import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import NotFound from './NotFound';
import { getStatusCode } from '../../selectors/status';

class PageContainer extends Component {
  getClassName = () => classNames({
    'morro-negrito-page-container': true,
  });

  pageContent = () => {
    const { status, children } = this.props;
    if (status === 404) {
      return <NotFound />;
    }
    // if (status === 500) {
    //   return <FiveHundred />;
    // }
    return children;
  }

  render() {
    return this.pageContent();
  }
}

PageContainer.propTypes = {
  children: PropTypes.node,
  status: PropTypes.number.isRequired,
};

PageContainer.defaultProps = {
  children: null,
};


export default connect(
  state => ({
    status: getStatusCode(state),
  }),
  // dispatch => ({}),
)(PageContainer);
