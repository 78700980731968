export default [
  {
    routeProps: {
      path: '/',
      exact: true,
    },
    loader: () => import(
      /* webpackChunkName: "HomePage" */
      './containers/routes/HomePage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/HomePage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/HomePage/index.js')],
  },
  {
    routeProps: {
      path: '/contact',
    },
    loader: () => import(
      /* webpackChunkName: "ContactPage" */
      './containers/routes/ContactPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/ContactPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/ContactPage/index.js')],
  },
  {
    routeProps: {
      path: '/accommodations',
    },
    loader: () => import(
      /* webpackChunkName: "AccommodationsPage" */
      './containers/routes/AccommodationsPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/AccommodationsPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/AccommodationsPage/index.js')],
  },
  {
    routeProps: {
      path: '/reservations',
    },
    loader: () => import(
      /* webpackChunkName: "ReservationsPage" */
      './containers/routes/ReservationsPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/ReservationsPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/ReservationsPage/index.js')],
  },
  {
    routeProps: {
      path: '/waves',
    },
    loader: () => import(
      /* webpackChunkName: "WavesPage" */
      './containers/routes/WavesPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/WavesPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/WavesPage/index.js')],
  },
  {
    routeProps: {
      path: '/surf-school',
    },
    loader: () => import(
      /* webpackChunkName: "SurfSchoolPage" */
      './containers/routes/SurfSchoolPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/SurfSchoolPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/SurfSchoolPage/index.js')],
  },
  {
    routeProps: {
      path: '/terms-policy-conditions',
    },
    loader: () => import(
      /* webpackChunkName: "TermsPolicyConditionsPage" */
      './containers/routes/TermsPolicyConditionsPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/TermsPolicyConditionsPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/TermsPolicyConditionsPage/index.js')],
  },
  {
    routeProps: {
      path: '/investment-opportunities',
    },
    loader: () => import(
      /* webpackChunkName: "InvestmentOpportunitiesPage" */
      './containers/routes/InvestmentOpportunitiesPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/InvestmentOpportunitiesPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/InvestmentOpportunitiesPage/index.js')],
  },
  {
    routeProps: {
      path: '/frequently-asked-questions',
    },
    loader: () => import(
      /* webpackChunkName: "FrequentlyAskedQuestionsPage" */
      './containers/routes/FrequentlyAskedQuestionsPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/FrequentlyAskedQuestionsPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/FrequentlyAskedQuestionsPage/index.js')],
  },
  {
    routeProps: {
      path: ['/deposit/:amount', '/deposit'],
    },
    loader: () => import(
      /* webpackChunkName: "DepositPage" */
      './containers/routes/DepositPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/DepositPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/DepositPage/index.js')],
  },
  {
    routeProps: {
      path: '/gallery',
    },
    loader: () => import(
      /* webpackChunkName: "GalleryPage" */
      './containers/routes/GalleryPage/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/GalleryPage/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/GalleryPage/index.js')],
  },
  {
    routeProps: {
      path: '*',
      status: 404,
    },
    loader: () => import(
      /* webpackChunkName: "NotFound" */
      './containers/routes/NotFound/index.js',
    ),
    loading: () => null,
    modules: ['./containers/routes/NotFound/index.js'],
    webpack: () => [require.resolveWeak('./containers/routes/NotFound/index.js')],
  },
];
