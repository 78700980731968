import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer id="footer">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-4 pull-right">
          <h5>Contact</h5>
          <ul className="list-unstyled quick-links">
            <li>
              <a
                href="https://wa.me/50765616206"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-whatsapp" />+507-65616206 (Panama)
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/17603328105"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-whatsapp" />+1-760-332-8105 (USA)
              </a>
            </li>
            <li>
              <a
                href="https://facebook.com/morronegritosurf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook" />@morronegritosurf
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/morronegrito"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram" />@morronegrito
              </a>
            </li>
            <li>
              <a
                href="mailto: info@morronegrito.travel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-envelope" />info@morronegrito.travel
              </a>
            </li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-4">
          <h5>Primary Links</h5>
          <ul className="list-unstyled quick-links">
            <li><Link to="/reservations">Reservations</Link></li>
            <li><Link to="/waves">Waves</Link></li>
            <li><Link to="/surf-school">Surf School</Link></li>
            <li><Link to="/accommodations">Accommodations</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-4">
          <h5>Related Links</h5>
          <ul className="list-unstyled quick-links">
            <li><Link to="/frequently-asked-questions">FAQ</Link></li>
            <li><Link to="/deposit">Deposit</Link></li>
            <li><Link to="/terms-policy-conditions">Terms, Privacy & Conditions</Link></li>
            <li><a href="/sitemap.xml">Sitemap</a></li>
            <li>
              <a
                href="https://www.spearfishingpanama.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Spearfishing Panama
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
