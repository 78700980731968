import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import routerMiddleware from 'react-router-redux/middleware';
import { createTracker } from 'redux-segment';
import rootReducer from '../reducers';

const pageMapper = {
  mapper: {
    '@@router/LOCATION_CHANGE': () => ({}),
  },
};

export default function configureStore(initialState, history) {
  const enhancer = compose(applyMiddleware(
    thunk,
    createTracker(pageMapper),
    routerMiddleware(history),
  ));
  const store = createStore(rootReducer, initialState, enhancer);
  return store;
}
