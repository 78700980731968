import React from 'react';
import Route from 'react-router/Route';
import Switch from 'react-router/Switch';
import Loadable from 'react-loadable';
import PageContainer from '../../containers/routes/PageContainer';
import routesConfig from '../../routesConfig';
import Navigation from '../Navigation';
import Footer from '../Footer';
import logo from '../../public/logo.png';

/**
 * NOTE: For server side rendering to work properly, we have to instantiate the Loadable components
 * outside of the JSX so that they are preloaded when we call `Loadable.preloadAll()` on the server
 * side.
 */
const routes = routesConfig.map(({ routeProps, loader, loading, modules, webpack }) => ({
  routeProps,
  component: Loadable({ loader, loading, modules, webpack }),
}));

const Routes = () => (
  <div className="container" >
    <header>
      <Navigation brand={{ image: logo }} />
    </header>
    <div className="content">
      <Route path="/" component={PageContainer} />
      <Switch>
        {routes.map(({ routeProps, component }) => (
          <Route key={routeProps.path} {...routeProps} component={component} />
        ))}
      </Switch>
    </div>
    <div className="clearfix" />
    <Footer />
  </div>
);

export default Routes;
