import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import browser from './browser';
import status from './status';

export default combineReducers({
  browser,
  routing: routerReducer,
  status,
});
