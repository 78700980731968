import createReducer from './createReducer';
import { NOT_FOUND } from '../actions/status';

const initialState = { code: 200 };
const handlers = {};

handlers[NOT_FOUND] = state => ({
  ...state,
  code: 404,
});

export default createReducer(handlers, initialState);
