import createReducer from './createReducer';
import {
  FETCH_BROWSER_DATA,
  FETCH_BROWSER_DATA_SUCCESS,
  FETCH_BROWSER_DATA_FAILURE,
 } from '../actions/browser';

const initialState = {
  browserData: {
    name: null,
    version: null,
  },
  loading: false,
};

const handlers = {};
handlers[FETCH_BROWSER_DATA] = state => ({
  ...state,
  loading: true,
});

handlers[FETCH_BROWSER_DATA_SUCCESS] = (state, { browserData }) => ({
  ...state,
  loading: false,
  browserData,
});

handlers[FETCH_BROWSER_DATA_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
