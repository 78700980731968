import UAParser from 'ua-parser-js';

export const FETCH_BROWSER_DATA = 'FETCH_BROWSER_DATA';
export const FETCH_BROWSER_DATA_SUCCESS = 'FETCH_BROWSER_DATA_SUCCESS';
export const FETCH_BROWSER_DATA_FAILURE = 'FETCH_BROWSER_DATA_FAILURE';

export const fetchBrowserData = headers => (dispatch) => {
  dispatch({
    type: FETCH_BROWSER_DATA,
  });
  try {
    const userAgent = headers['user-agent'];
    const parser = new UAParser();
    const ua = userAgent;
    const browserName = parser.setUA(ua).getBrowser().name;
    const fullBrowserVersion = parser.setUA(ua).getBrowser().version;
    const browserVersion = fullBrowserVersion.split('.', 1).toString();
    const browserVersionNumber = Number(browserVersion);
    const browserData = {
      name: browserName,
      version: browserVersionNumber,
    };
    dispatch({
      type: FETCH_BROWSER_DATA_SUCCESS,
      browserData,
    });
  } catch (error) {
    dispatch({
      type: FETCH_BROWSER_DATA_FAILURE,
      error,
    });
  }
};
