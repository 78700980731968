import React from 'react';
import PropTypes from 'prop-types';

const NotFound = ({ staticContext }) => {
  if (staticContext) {
    // This will force a redirect on the server side when the routes are evaluated
    // eslint-disable-next-line no-param-reassign
    staticContext.status = 404;
  }

  return (
    <div className="not-found-page">
      <h1>Page not found.</h1>
    </div>
  );
};

NotFound.propTypes = {
  staticContext: PropTypes.shape({
    status: PropTypes.number,
    url: PropTypes.string,
  }),
};

NotFound.defaultProps = {
  staticContext: {},
};

export default NotFound;
