import React from 'react';
import createHistory from 'history/createBrowserHistory';
import ConnectedRouter from 'react-router-redux/ConnectedRouter';
import { render, unmountComponentAtNode } from 'react-dom';
import Loadable from 'react-loadable';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import configureStore from '../stores';

import '../styles/base.scss';

const root = document.querySelector('#root');
const preloadedState = window.__DATA__; // eslint-disable-line no-underscore-dangle

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();

const store = configureStore(preloadedState, history);

const mount = () => {
  // eslint-disable-next-line global-require,import/newline-after-import
  const Routes = require('../components/Routes').default;

  Loadable.preloadReady().then(() => {
    render(
      <AppContainer>
        <Provider store={store} >
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </Provider>
      </AppContainer>,
      root,
    );
  });
};

mount();

if (module.hot) {
  module.hot.accept('../components/Routes', () => {
    unmountComponentAtNode(root);
    mount();
  });
}
