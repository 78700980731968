import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on: false,
    };
  }

  render() {
    const {
      brand,
    } = this.props;
    return (
      <Navbar id="menu" collapseOnSelect expand="lg">
        <Navbar.Brand>
          <Link className="navbar-brand" to="/">
            {brand.image ? <img src={brand.image} alt={brand.text} /> : brand.text}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <LinkContainer to="/reservations">
              <Nav.Link>
                Reservations
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/waves">
              <Nav.Link>
                Waves
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/surf-school">
              <Nav.Link>
                Surf School
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/accommodations">
              <Nav.Link>
                Accommodations
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/gallery">
              <Nav.Link>
                Gallery
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link>
                Contact
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
    // return (
    //   <Navbar id="menu" default collapseOnSelect>
    //     <Navbar.Header>
    //       <Navbar.Toggle />
    //       <Navbar.Brand>
    //         <Link to="/">
    //           {brand.image ? <img src={brand.image} alt={brand.text} /> : brand.text}
    //         </Link>
    //       </Navbar.Brand>
    //     </Navbar.Header>
    //     <Navbar.Collapse>
    //       <Nav pullRight>

    //       </Nav>
    //     </Navbar.Collapse>
    //   </Navbar>
    // );
  }
}

Navigation.propTypes = {
  brand: PropTypes.shape({
    image: PropTypes.string,
    text: PropTypes.string,
  }),
};

Navigation.defaultProps = {
  brand: {
    image: null,
    text: 'Morro Negrito',
  },
};

export default Navigation;
